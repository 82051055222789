import { BaseLookup } from "./BaseLookup";

const seasonRegex = new RegExp(/(sr:season:(\d*);?)/mi);

export class Tournament extends BaseLookup {
    sportCategoryId: string;
    sortOrder: number;

    /**
     * Original tournament season provider id
     */
    seasonProviderId?: string;

    /**
     * Key for showing tournament icon
     */
    iconUrl: string;

    get srSeasonId(): string | undefined {
        if (this.seasonProviderId != null && this.seasonProviderId !== '' && seasonRegex.test(this.seasonProviderId)) {
            // NOTE: this is not correct. In case there are multiple vlaue in seasonProviderId this will 100% fail
            return this.seasonProviderId.split(':')[2];
        }
    }

    constructor(data: any) {
        super();

        this.id = data['id'];
        this.name = data['name'];
        this.abrv = data['abrv'];
        this.sportCategoryId = data['sportCategoryId'];
        this.sortOrder = data['sortOrder'];
        this.seasonProviderId = data['seasonProviderId'];
        this.iconUrl = data['iconUrl'];
    }
}