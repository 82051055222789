import { ArgumentNullError } from "../../errors";
import { ISpecifiers } from "./types";

export default class Utility {
    static ensureSpecifiersNotNull(specifiers?: ISpecifiers): void {
        // if (specifiers == null || Object.keys(specifiers).length === 0) {
        if (specifiers == null) {
            throw new ArgumentNullError('specifiers');
        }
    }

    static numberToStringWithSign(value: number) {
        if (value > 0) {
            return `+${value}`;
        }
        else if (value < 0) {
            return `${value}`;
        }
        return "0";
    }
}