interface ITournamentMenuItem {
	tournament: {
		name: string;
		abrv: string;
		id: string;
		sortOrder: number;
		sportCategoryId: string;
	};
	count: number;
}

interface ISportCategoryMenuItem {
	sportCategory: {
		name: string;
		abrv: string;
		id: string;
		sortOrder: number;
		sportId: string;
	};
	count: number;
	subItems: ITournamentMenuItem[]
}

interface ISportMenuItem {
	sport: {
		name: string;
		abrv: string;
		id: string;
		sortOrder: number;
	};
	count: number;
	subItems: ISportCategoryMenuItem[];
}

export class MenuSubscriptionResponse {
	subscriptionId: string;
	menu: ISportMenuItem[];
}