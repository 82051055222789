import { ArgumentNullError } from '../../errors';
import { stringFormat } from '../string-format';
import NameExpressionFactory from './NameExpressionFactory';
import NameExpressionHelpers from './NameExpressionHelpers';
import { INameExpression, INameExpressionFactory, ISpecifiers } from './types';

/**
 * Tip name provider - used for parsing tip names with specifiers
 */
class NameProvider {
    private nameExpressionFactory: INameExpressionFactory;

    constructor() {
        this.nameExpressionFactory = new NameExpressionFactory();
    }

    private getTipNameExpressions(tip: string, specifiers: ISpecifiers): { format: string, expressions: INameExpression[] } {
        if (tip == null || tip === '') {
            throw new ArgumentNullError('tip');
        }

        const descriptorParseResult = NameExpressionHelpers.parseDescriptor(tip);
        if (descriptorParseResult == null) {
            return null;
        }

        const expressions: INameExpression[] = [];
        descriptorParseResult.expressions.forEach(exp => {
            const expressionParseResult = NameExpressionHelpers.parseExpression(exp);

            // build expression using expression factory
            expressions.push(this.nameExpressionFactory.buildExpression(specifiers, expressionParseResult.operator, expressionParseResult.operand));
        });

        return {
            format: descriptorParseResult.format,
            expressions: expressions
        };
    }

    /**
     * @param tip tip name to parse
     */
    getName(tip: string | null | undefined, specifiers: ISpecifiers, locale: string = 'en'): string {
        if (tip == null || tip === '') {
            return '';
        }

        const ns = this.getTipNameExpressions(tip, specifiers);

        if (ns == null) {
            return tip;
        }

        const expressionResults = ns.expressions.map(e => e.buildName(locale));

        return stringFormat(ns.format, ...expressionResults);
    }
}

export default NameProvider;