import { HomeAwayScore } from "./HomeAwayScore";
import { ScorePerPeriod } from "./ScorePerPeriod";
import { TeamStatistics } from "./TeamStatistics";

export class Result {
    scorePerPeriod: ScorePerPeriod[];
    currentGameScore?: HomeAwayScore;
    currentScore?: HomeAwayScore;
    halfTimeScore?: HomeAwayScore
    overTimeScore?: HomeAwayScore

    /**
     * True if there is a tiebreak.
     * 
     * Relevant for Tennis
     */
    tiebreak?: boolean;
    /**
     * If tiebreak is true, this might(if inner scores are not 0) have value.
     */
    tiebreakScore?: HomeAwayScore;

    serve?: 'home' | 'away';

    homeTeamTotalStatistics: TeamStatistics;
    awayTeamTotalStatistics: TeamStatistics;

}