import { ComparerFn, ComparerResult } from "../common";

export interface INameType {
	name: string;
}

/**
 * Compares elements by id property
 */
export const nameComparer: ComparerFn<INameType> = function (a: INameType, b: INameType): ComparerResult {
	if (a.name < b.name) return -1;
	if (a.name > b.name) return 1;

	return 0;
}