import { EventKeyBettingOffer } from "@gp/models";
import { ComparerFn, ComparerResult } from "../common";

import { idComparer } from "./idComparer";
import { isLiveComparer, reverseIsLiveComparer } from "./isLiveComparer";
import { isTopEventComparer } from "./isTopEventComparer";
import { nameComparer } from "./nameComparer";
import { sortOrderComparer } from "./sortOrderComparer";
import { startTimeComparer } from "./startTimeComparer";

export type SortProperties<T> =
  | "sortOrder"
  | "startTime"
  | "id"
  | "isTop"
  | "name"
  | "isLive"
  | "!isLive"
  | ComparerFn<T>;

export class Sorter {
  /**
   * Sorts given offer by value in ascending order
   * @param offers offer to sort
   * @returns sorted offers
   */
  static sortOfferByValue(
    offers: EventKeyBettingOffer[]
  ): EventKeyBettingOffer[] {
    const sortedOffer = offers.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;

      // if values are the same, sort by ID
      return idComparer(a, b);
    });

    return sortedOffer;
  }

  /**
   * Sorts given offer by tip in descending order
   * @param offers offer to sort
   * @returns sorted offers
   */
  static sortOfferByTip(
    offers: EventKeyBettingOffer[]
  ): EventKeyBettingOffer[] {
    const sortedOffer = offers.sort((a, b) => {
      if (a.teamAbrv != null && b.teamAbrv != null && a.teamAbrv.toLowerCase() < b.teamAbrv.toLowerCase()) return 1;
      if (a.teamAbrv != null && b.teamAbrv != null && a.teamAbrv.toLowerCase() > b.teamAbrv.toLowerCase()) return -1;

      return 0;
    });

    return sortedOffer;
  }

  static sortOfferByOverUnder(offers: EventKeyBettingOffer[]): EventKeyBettingOffer[] {
    return offers.sort((a, b) => {
      if (a.teamAbrv != null && a.teamAbrv.includes("over")) return -1;
      if (b.teamAbrv != null && b.teamAbrv.includes("over")) return 1;
      return 0;
    })
  }

  /**
   * Creates sorter function
   * @param sortProperties
   * @returns
   */
  static sort<T>(...sortProperties: SortProperties<T>[]): ComparerFn<T> {
    const sortFns: ComparerFn<any>[] = [];

    sortProperties.forEach((sp) => {
      if (typeof sp === "function") {
        sortFns.push(sp);
        return;
      }

      switch (sp) {
        case "sortOrder":
          sortFns.push(sortOrderComparer);
          break;
        case "startTime":
          sortFns.push(startTimeComparer);
          break;
        case "id":
        default:
          sortFns.push(idComparer);
          break;
        case "isTop":
          sortFns.push(isTopEventComparer);
          break;
        case "name":
          sortFns.push(nameComparer);
          break;
        case "isLive":
          sortFns.push(isLiveComparer);
          break;
        case "!isLive":
          sortFns.push(reverseIsLiveComparer);
          break;
      }
    });

    return function (a: T, b: T) {
      let _res: ComparerResult = 0;
      for (let sf of sortFns) {
        _res = sf(a, b);

        if (_res !== 0) {
          break;
        }
      }

      return _res;
    };
  }
}
