import { BetSlipContainer } from "./BetSlipContainer";

export class BetSlipRequest {
    /**
     * Accept quota changes during submit.
     * If false, bet slip will stop processing if any quota changes
     */
    acceptOddsChanges: boolean;
    bettingAccountTypeId: string | null;
    betSlipsFromRequest: BetSlipContainer[];
}