export class Team {
    id: string;
    name: string;
    abrv: string;
    /**
     * any - male/unknown team gender
     * 1 - female
     */
    gender?: number;
    countryCode?: string;
}