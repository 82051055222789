import { BetSlipOfferSportData } from '../BetSlipOfferSportData'
import {BetSlipOffer}from'../BetSlipOffer'

export class BetSlipValidationError {
    /** Also is equal to betting offer id. */
    correlationId?: string;
    /** Also is equal to betting offer id. */
    correlationTwoId?: string;
    bettingOfferKeyId?: string;
    eventId?: string;

    errorCode: number;
    errorMessage: string;

    errorOnLevel: number;

    playerId?: string;
    teamId?: string;

    /** @todo is this from response? */
    totalNumberOfOffersPerBetSlip?: string;
    /** @todo is this from response? */
    suggestedValue?: string;


    /** Added during client side mapping. */
    eventTwoId?: string;
    /** Added during client side mapping. */
    bettingTypeOne?: string;
    /** Added during client side mapping. */
    bettingTypeTwo?: string;
    /** Added during client side mapping. */
    sportData?: BetSlipOffer[];
    /** Added during client side mapping. */
    event?: BetSlipOfferSportData;
    /** Added during client side mapping. */
    bettingOfferId?: string;
}