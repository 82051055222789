import { createContext } from "react";

export type AdditionalOfferOptions = {
	/**
	 * List of key ids that should be excluded from rendering
	 */
	excludeKeyIds?: string[];
	/**
	 * Called when additional offer is about to close
	 */
	onClose: () => void;
}

const defaultValue: AdditionalOfferOptions = {
	onClose: () => { }
}

export const AdditionalOfferOptionsContext = createContext<AdditionalOfferOptions>(defaultValue);