(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@gp/models"), require("@gp/offer"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@gp/models", "@gp/offer"], factory);
	else if(typeof exports === 'object')
		exports["gp"] = factory(require("react"), require("@gp/models"), require("@gp/offer"));
	else
		root["gp"] = factory(root["react"], root["@gp/models"], root["@gp/offer"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__297__, __WEBPACK_EXTERNAL_MODULE__879__, __WEBPACK_EXTERNAL_MODULE__551__) {
return 