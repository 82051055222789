import { ArgumentNullError, FormatError } from "../../errors";

import { IDescriptorParseResult, IExpressionParseResult } from "./types";

export default class NameExpressionHelpers {
    static operators: string[] = [
        '+', // leave value as is but add + sign infront
        '-', // negated value and add - sign infront
        '$', // competitor (team/player) or event
        '%', // player - treated the same as $
        '!', // ordinal number
    ]

    static parseDescriptor(descriptor: string): IDescriptorParseResult {
        if (descriptor == null || descriptor === '') {
            throw new ArgumentNullError('descriptor');
        }

        let currIdx = 0;
        let format = descriptor;

        const expressions: string[] = [];

        do {
            let startIdx = descriptor.indexOf('{', currIdx);
            let endIdx = descriptor.indexOf('}', currIdx);

            // no curly braces found - exit
            if (startIdx < 0 && endIdx < 0) {
                break;
            }

            // partial find - invalid format
            if (startIdx < 0 || endIdx < 0 || endIdx <= startIdx) {
                throw new Error(`Format of the descriptor '${descriptor}' is incorrect. Each opening '{' must be closed with '}'`)
            }

            // extracts descriptor with curly braces
            const expression = descriptor.substr(startIdx, endIdx - startIdx + 1);
            format = format.replace(expression, '{' + expressions.length + '}');
            expressions.push(expression);

            currIdx = (endIdx === descriptor.length) ? endIdx : endIdx + 1;
        }
        while (true);

        return expressions.length > 0 ? ({
            expressions: expressions,
            format: format
        }) : null;
    }

    static parseExpression(expression: string): IExpressionParseResult {
        if (expression == null || expression === '') {
            throw new ArgumentNullError('expression');
        }

        if (expression.length < 3) {
            throw new FormatError('Minimum expression length is 3.');
        }
        if (!expression.startsWith('{')) {
            throw new FormatError('Expression must start with \'{\'');
        }
        if (!expression.endsWith('}')) {
            throw new FormatError('Expression must end with \'}\'');
        }

        const operator = expression.substr(1, 1);
        if (!this.operators.includes(operator)) {
            return {
                operator: null,
                operand: expression.substr(1, expression.length - 2)
            }
        }
        else {
            return {
                operator: operator,
                operand: expression.substr(2, expression.length - 3) // -3 because of shift to the right by 1 character
            }
        }
    }
}
