import { IOperand } from "../types";
import { INameExpression } from "../types/INameExpression";
import Utility from "../utility";

export class MinusNameExpression implements INameExpression {
    private operand: IOperand;

    constructor(operand: IOperand) {
        this.operand = operand;
    }

    buildName(locale: string): string {
        const value = this.operand.getNumericValue();
        return Utility.numberToStringWithSign(value * -1);
    }
}