import { BaseLookup } from "./BaseLookup";

export class BettingTypeGroup extends BaseLookup {
    sortOrder: number;

    static create(id: string, name: string, abrv: string, sortOrder: number): BettingTypeGroup {
        const btg = new BettingTypeGroup();
        btg.id = id;
        btg.name = name;
        btg.abrv = abrv;
        btg.sortOrder = sortOrder;

        return btg;
    }
}