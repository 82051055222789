import { ArgumentNullError } from "../../errors";
import { ISpecifiers } from "./types";
import Utility from "./utility";

export abstract class BaseOperand {
    protected specifiers: ISpecifiers;
    protected operandString: string;

    constructor(specifiers: ISpecifiers, operandString: string) {
        Utility.ensureSpecifiersNotNull(specifiers);

        if (operandString == null || operandString === '') {
            throw new ArgumentNullError('operandString');
        }

        this.specifiers = specifiers;
        this.operandString = operandString;
    }

    parseSpecifierString(specifierName: string, specifiers: ISpecifiers): number {
        Utility.ensureSpecifiersNotNull(specifiers);
        if (specifierName == null || specifierName === '') {
            throw new ArgumentNullError('specifierName');
        }

        const specifierValueString: string = specifiers[specifierName];

        if (specifierValueString == null) {
            throw new Error(`Specifier with name ${specifierName} does not exist.`);
        }

        const specifierValue: number = Number(specifierValueString);

        if (isNaN(specifierValue)) {
            throw new Error(`Specifier[key=${specifierName}, value=${specifierValue}] must be a string representation of numeric type.`);
        }

        return specifierValue;
    }

}