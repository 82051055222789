import { BaseOperand } from "./BaseOperand";
import { IOperand, ISpecifiers, MathOperations } from "./types";

export class ExpressionOperand extends BaseOperand implements IOperand {
    private operation: MathOperations;
    private operationValue: number;

    constructor(specifiers: ISpecifiers, operandString: string, operation: MathOperations, operationValue: number) {
        super(specifiers, operandString);

        this.operation = operation;
        this.operationValue = operationValue;
    }

    getNumericValue(): number {
        const value = this.parseSpecifierString(this.operandString, this.specifiers);

        switch (this.operation) {
            case MathOperations.ADD:
                return value + this.operationValue;
            case MathOperations.SUBTRACT:
                return value - this.operationValue;
            default:
                throw new Error('Invalid math operation.');
        }
    }

    getStringValue(): string {
        throw new Error('Not supported');
    }

}