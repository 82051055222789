import { BaseOperand } from "./BaseOperand";
import { IOperand, ISpecifiers } from "./types";

export class SimpleOperand extends BaseOperand implements IOperand {
    constructor(specifiers: ISpecifiers, operandString: string) {
        super(specifiers, operandString);
    }

    getNumericValue(): number {
        return this.parseSpecifierString(this.operandString, this.specifiers);
    }

    getStringValue(): string {
        const specifierValue = this.specifiers[this.operandString];

        if (specifierValue == null) {
            // throw new Error(`Specifiers do not contain a specifier with key=${this.operandString}`);
            console.warn(`Specifiers do not contain a specifier with key=${this.operandString}.`);
            return '';
        }

        return specifierValue;
    }
}