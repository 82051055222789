import { BettingType, EventKey } from "@gp/models";
import { constants, getSortedEventKeysBySpecifierValue, Selector, SportSelectors } from "@gp/offer";

import { ColumnType } from "./ColumnType";

export type OfferColumnComponentProps = {
	offerKeyId?: string;
	bettingType?: BettingType;
	tips?: string[];
}

export type OfferColumnConfiguration = {
	id: string;
	type: ColumnType;
	/**
	 * Used for mapping offer in the column
	 */
	componentProps?: OfferColumnComponentProps; //ReactElement<any, any>;
}

export class OfferColumnConfigurationResult {
	isEmpty: boolean = true;
	nonEmptyColumns: number = 0;
	columns: OfferColumnConfiguration[] = []
}

export function getOfferColumns(eventKeys: EventKey[], selectors: SportSelectors, numberOfColumns: number = 4): OfferColumnConfigurationResult {
	const initialValue = new OfferColumnConfigurationResult();

	if (selectors == null) {
		return initialValue;
	}

	Object.keys(selectors)
		.filter(col => col <= `${numberOfColumns}`)
		.forEach(col => {
			const selector: Selector = selectors[col];

			if (selector == null) {
				initialValue.columns.push({ id: col, type: ColumnType.EMPTY });
				return;
			}

			if (selector.bettingTypes.includes(constants.resultPlaceholder)) {
				initialValue.columns.push({ id: col, type: ColumnType.RESULT });
				return;
			}

			// find first key matched by betting type
			let columnKeys: EventKey[] = eventKeys.filter(ek => selector.bettingTypes.includes(ek.bettingType.abrv));

			// sort event keys by specifier
			columnKeys = getSortedEventKeysBySpecifierValue(columnKeys);

			const columnKey: EventKey = columnKeys[0];

			if (columnKey == null) {
				initialValue.columns.push({ id: col, type: ColumnType.EMPTY });
				return;
			}

			initialValue.columns.push({
				id: columnKey.id,
				type: ColumnType.TIP,
				componentProps: {
					offerKeyId: columnKey.id,
					bettingType: columnKey.bettingType,
					tips: selector.tips
				}
			})

			initialValue.nonEmptyColumns += 1;
			initialValue.isEmpty = false;
		});

	return initialValue;
}

