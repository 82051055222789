/**
 * C# like string formatter
 * @param input
 * @param args 
 * @returns 
 */
export function stringFormat(input: string, ...args: string[]) {
    if (args.length === 0) {
        return input;
    }

    const params = args.slice();

    for (let paramIndex in params) {
        input = input.replace(new RegExp("\\{" + paramIndex + "\\}", "gi"), args[paramIndex]);
    }

    return input;
};