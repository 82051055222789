import { ILogger } from "./ILogger";
import { LogLevel } from "./LogLevel";

export class ConsoleLogger implements ILogger {
    private _isEnabled: boolean;
    private _logLevel: LogLevel;

    constructor(isEnabled: boolean = true, logLevel: LogLevel = LogLevel.INFO) {
        this._isEnabled = isEnabled;
        this._logLevel = logLevel;
    }

    public logTrace(message?: any, ...optionalParams: any[]): void {
        if(!this._isEnabled || this._logLevel > LogLevel.TRACE) return
        console.trace(message, optionalParams)
    }

    public logError(message?: any, ...optionalParams: any[]): void {
        if(!this._isEnabled || this._logLevel > LogLevel.ERROR) return
        console.error(message, optionalParams);
    }

    public logWarn(message?: any, ...optionalParams: any[]): void {
        if(!this._isEnabled || this._logLevel > LogLevel.WARN) return
        console.warn(message, optionalParams)
    }

    public logInfo(message?: any, ...optionalParams: any[]): void {
        if(!this._isEnabled || this._logLevel > LogLevel.INFO) return
        console.info(message, optionalParams)
    }
}