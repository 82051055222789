import { BettingTip, BettingType, BettingTypeGroup, Category, Player, Sport, Team, Tournament } from "../lookups";
import { EventOffer } from "../offer/pageable/EventOffer";

import { SportCategoryGroup } from "../offer/pageable/SportCategoryGroup";

export class PageableOfferResponse {
	lookup: {
		sports?: { [sportId: string]: Sport };
		categories?: { [categoryId: string]: Category };
		tournaments?: { [tournamentId: string]: Tournament };
		teams?: { [teamId: string]: Team };
		players?: { [playerId: string]: Player };
		bettingTypes?: { [bettingTypeId: string]: BettingType<string> };
		bettingTypeGroups?: { [bettingTypeGroupId: string]: BettingTypeGroup };
		bettingTips?: { [bettingTipId: string]: BettingTip };
		isEmpty?: boolean
	};
	pageNumber: number;
	pageSize: number;
	totalNumberOfItems: number;
	/**
	 * If request is groupped by tournament, this will contain data
	 */
	items?: SportCategoryGroup[];
	/**
	 * 
	 */
	offer?: EventOffer[];
	/**
	 * Subscription starting offer.
	 * Initial offer will have value of 0
	 */
	startingVersion: number;
	/**
	 * Current offer version
	 */
	version: number;
}