import { Sport, Category, Tournament, Team, Player, BettingType, BettingTypeGroup, BettingTip, EventOffer } from '../index'
export class MostPlayedBySport {
    selectedSportGroup: {
        lookup: {
            sports?: {
                [sportId: string]: Sport;
            };
            categories?: {
                [categoryId: string]: Category;
            };
            tournaments?: {
                [tournamentId: string]: Tournament;
            };
            teams?: {
                [teamId: string]: Team;
            };
            players?: {
                [playerId: string]: Player;
            };
            bettingTypes?: {
                [bettingTypeId: string]: BettingType<string>;
            };
            bettingTypeGroups?: {
                [bettingTypeGroupId: string]: BettingTypeGroup;
            };
            bettingTips?: {
                [bettingTipId: string]: BettingTip;
            };
            isEmpty: boolean;
        };
        events: {
            sportId: string;
            events: {
                betCount: number;
                event: EventOffer
            }[]
        }
    };
    sports: {
        sport: Sport;
        count: number
    }[]
}
