export enum ProviderNameEnum {
    "BetRadar" = 1,
    "BetGenius" = 2,
    "LSports" = 3,
    "Huddle" = 4
}

export type SourceProps = {
    providerId: string,
    providerName: number
}

export class Source {
    /**
     * Match provider id. Use this to get statistics off of provider
     */
    providerId: string;

    providerName: ProviderNameEnum;

    get matchId(): string | null {

        if (!this.providerName) {
            if (this.providerId && this.providerId.includes("sr")) {
                return this.providerId.split(":")[2]
            } else {
                return null;
            }
        }

        switch (this.providerName) {
            case ProviderNameEnum.BetRadar:
                return this.providerId && this.providerId.split(":")[2]
            case ProviderNameEnum.LSports:
                return this.providerId
            default:
                return null
        }
    }

    constructor(eventSource: SourceProps) {
        if (eventSource) {
            this.providerId = eventSource.providerId;
            this.providerName = Number(eventSource.providerName);
        }
    }
}