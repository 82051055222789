import { BetSlipOfferSportData } from '../BetSlipOfferSportData'
import {BetSlipOffer}from'../BetSlipOffer'

export class BetSlipProcessingError {
    bettingOfferId?: string;
    bettingOfferKeyId?: string;
    eventId?: string;
    errorCode: number;
    errorMessage: string;

    /** @todo check is this from server response. */
    quotaValueDiffIndicator: number;
    /** @todo is this from response? */
    totalNumberOfOffersPerBetSlip?: string;
    /** @todo is this from response? */
    suggestedValue?: string;

    /** Added during client side mapping. */
    eventTwoId?: string;
    /** Added during client side mapping. */
    correlationId?: string;
    /** Added during client side mapping. */
    correlationTwoId?: string;
    /** Added during client side mapping. */
    sportData?: BetSlipOffer[];
    /** Added during client side mapping. */
    event?: BetSlipOfferSportData;
    /** Added during client side mapping. */
    bettingTypeOne?: string;
    /** Added during client side mapping. */
    bettingTypeTwo?: string;
}