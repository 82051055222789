export class BetSlipOfferSportData {
    bettingTypeNameForBettingSlip: string;
    result: string;
    eventStartTime: Date;
    eventType: number;
    teamOneId: string;
    teamOneName: string;
    teamTwoId: string;
    teamTwoName: string;
    tournamentSeasonId?: string;
    tournamentSeasonName?: string;
    eventName?: string;
    tournamentName: string;
    /** @todo is this from response? */
    teamOneGender?: 0 | 1;
    /** @todo is this from response? */
    teamTwoGender?: 0 | 1;
    /** @todo is this from response? */
    sportAbrv?: string;

    /** Added during client side mapping. */
    isEventDeleted?: boolean;
}