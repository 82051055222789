import { BettingTip, BettingType, BettingTypeGroup, Category, Player, Sport, Team, Tournament } from "../lookups";
import { EventUpdate, EventDelete, IChange } from "../offer";

export class OfferSubscriptionResponse {
    subscriptionId: string;
    lookups: {
        sports?: { [sportId: string]: Sport };
        categories?: { [categoryId: string]: Category };
        tournaments?: { [tournamentId: string]: Tournament };
        teams?: { [teamId: string]: Team };
        players?: { [playerId: string]: Player };
        bettingTypes?: { [bettingTypeId: string]: BettingType<string> };
        bettingTypeGroups?: { [bettingTypeGroupId: string]: BettingTypeGroup };
        bettingTips?: { [bettingTipId: string]: BettingTip };
        isEmpty: boolean
    };
    offerChanges: IChange<EventUpdate, EventDelete>;
    /**
     * Subscription starting offer.
     * Initial offer will have value of 0
     */
    startingVersion: number;
    /**
     * Current offer version
     */
    version: number;
}