import { toNDecimals } from '../helpers';

export class OddsConverter {
    /**
     * Converts decimal odd value to fractional in format x/y
     * @param value decimal odd value
     */
    static decimalToFractional(value: number): string {
        if (value == null || isNaN(value)) {
            return null;
        }

        value = toNDecimals(value - 1);

        let denominator = 1;
        for (denominator = 1; (value * denominator) % 1 !== 0; denominator++);
        return `${value * denominator}/${denominator}`;
    }

    /**
     * Converts decimal odd value to american
     * @param value decimal odd value
     * @returns {string}
     */
    static decimalToAmerican(value: number): string {
        if (!value) {
            return null;
        }

        if (value >= 2) {
            return Math.round((value - 1) * 100).toFixed(0);
        }

        return Math.round(-100 / (value - 1)).toFixed(0);
    }
}