import { IOperand } from "../types";
import { INameExpression } from "../types/INameExpression";

export class CardinalNameExpression implements INameExpression {
    private operand: IOperand;

    constructor(operand: IOperand) {
        this.operand = operand;
    }

    buildName(locale: string): string {
        return this.operand.getStringValue();
    }
}