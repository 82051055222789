import { IDictionary } from "../common";

export class BettingOffer {
    id: string;
    /**
     * Used for calculations on the server.
     */
    tip: string;
    /**
     * **NOTE** Used only for displaying!
     * 
     * Used for displaying tip on the client.
     */
    displayTip: string;
    teamId?: string;
    /**
     * any - male/unknown team gender
     * 1 - female
     */
    gender?: number;
    playerId?: string;
    specifier?: IDictionary<string>;
    value: number;
    isLocked?: boolean;
    status: number;
    countryCode?: string;

    constructor(bettingOfferData: any) {
        this.id = bettingOfferData.id;
        this.tip = bettingOfferData.tip;
        this.displayTip = bettingOfferData.displayTip;
        this.teamId = bettingOfferData.teamId;
        this.gender = bettingOfferData.gender;
        this.playerId = bettingOfferData.playerId;
        this.specifier = bettingOfferData.specifier;
        this.value = bettingOfferData.value;
        this.isLocked = bettingOfferData.isLocked;
        this.status = bettingOfferData.status;
        this.countryCode = bettingOfferData?.countryCode;
    }
}