import { ComparerFn, ComparerResult } from "../common";

export interface ISortOrderType {
	sortOrder: number;
}

/**
 * Compares elements by sort order property
 */
export const sortOrderComparer: ComparerFn<ISortOrderType> = function (a: ISortOrderType, b: ISortOrderType): ComparerResult {
	if (a.sortOrder < b.sortOrder) return -1;
	if (a.sortOrder > b.sortOrder) return 1;

	return 0;
}