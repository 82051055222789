import { ComparerResult } from "../common";
import { ObservableMap } from "mobx";
import { Event, Team, EventKey } from "@gp/models";

// Knowing whose name will be on this commit makes me wonder if this is how Luke felt when he found out who is his father?
type ComparerFn = (a: EventKey, b: EventKey, event: Event, teamLookup: ObservableMap<string, Team>) => ComparerResult;

const NBA_BETTING_TYPES = ["player-total-assists", "player-total-rebounds", "player-total-3-point-field-goals", "player-total-points"]


/**
 * Compares NBA betting types with player team names
 */
export const NBATeamNameComparer: ComparerFn = function (a, b, event, teamLookup) {

    if (
        a.bettingType.abrv === b.bettingType.abrv &&
        NBA_BETTING_TYPES.includes(a.bettingType.abrv)
    ) {
        const teamOneName = teamLookup.get(event.teamOneId)?.name;

        if (teamOneName) {
            const aIsTeamOne =
                a.specifier?.player?.includes(teamOneName);

            const bIsTeamOne =
                b.specifier?.player?.includes(teamOneName);

            if (aIsTeamOne === bIsTeamOne) return 0;

            if (aIsTeamOne) return -1;
            if (bIsTeamOne) return 1;
        }
    }

    return 0;
}