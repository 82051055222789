import ordinalExtensions from "../../models/ordinalExtensions";
import { IOperand } from "../types";
import { INameExpression } from "../types/INameExpression";

export class OrdinalNameExpression implements INameExpression {
    private operand: IOperand;

    constructor(operand: IOperand) {
        this.operand = operand;
    }

    private getOrdinal(value: number, locale: string = 'en'): string {
        const ten = value % 10;
        const hundred = value % 100;

        const extensions = ordinalExtensions.getExtensions(locale);

        // default
        let ext = extensions[0];

        if (
            // first
            (ten === 1 && hundred !== 11) ||
            // second
            (ten === 2 && hundred !== 12) ||
            // third
            (ten === 3 && hundred !== 13)
        ) {
            ext = extensions[ten];
        }

        return value + ext;
    }

    buildName(locale: string): string {
        const value = this.operand.getNumericValue();
        return this.getOrdinal(value, locale);
    }
}