import { CurrentScore } from "./CurrentScore";
import { MatchTime } from "./MatchTime";
import { Result } from "./Result";
import { Source } from "./Source";

export enum EventType {
    NORMAL,
    SHORT_TERM,
    LONG_TERM
};

export enum BettingStatus {
    /**
     * Inactive betting
     */
    INACTIVE = -1,
    /**
     * Active betting
     */
    ACTIVE = 1
};

export enum LiveStatus {
    PREMATCH = 0,
    LIVE = 1
};

export type LiveStreamStatus = "live" | "upcoming" | "cancelled";

export class Event {
    bettingStatus: BettingStatus;
    /**
     * Event id
     */
    id: string;
    /**
     * Date in ISO format
     */
    liveChange: string;
    liveStatus: LiveStatus;

    /**
     * Indicates if event is live or not
     */
    get isLive() {
        return this.liveStatus === LiveStatus.LIVE;
    }

    /**
     * If true event is locked for betting
     * All event offers will be locked as well
     */
    isLocked: boolean;
    eventType?: EventType;

    /**
     * Indicates if event is outright or not
     */
    get isOutright() {
        return this.eventType === EventType.SHORT_TERM || this.eventType === EventType.LONG_TERM;
    }
    /**
     * Match status abrv
     */
    matchStatus: string;
    /**
     * True if event has ended
     */
    ended?: boolean;
    /** ISO Date: Event start time. */
    startTime: string;
    sportId: string;
    sportCategoryId: string;
    tournamentId: string;
    stopReason: string;
    teamOneId?: string;
    teamTwoId?: string;
    season?: string;
    note: string;
    /**
     * Count of entire offer available for this event
     */
    totalOfferCount: number;
    matchTime?: MatchTime;
    currentScore?: CurrentScore;
    result?: Result;
    /**
     * Source of this event.
     * If not null, then this event originates from BR
     */
    source?: Source;
    /**
     * Indicates if event is top or not.
     * If true, event should be displayed higher up
     */
    isTopEvent: boolean;
    /**
     * Indicates an event that will be in live later on
     * If true, event will appear in live offer
     */
    hasLiveCoverage?: boolean;

    /**
     * Indicates if an event has TV coverage or not.
     * Note: TV channels are not received and should be loaded on demand (e.g. on click)
     */
    hasTvCoverage?: boolean;

    /**
     * Event daily id
     */
    dailyId?: { decimal: number, hex: string };
    /**
     * Event weekly id
     */
    weeklyId?: { decimal: number, hex: string };
    /**
     * Event long term id - valid only for outright events
     */
    longTermId?: { decimal: number, hex: string };

    /**
     * True if event is upcoming event, false otherwise
     */
    isUpcoming: boolean;

    /**
     * Outright event name. Has value only if event is outright
     */
    name?: string;
    liveStreamStatus?: LiveStreamStatus

    constructor(eventData: any) {
        this.bettingStatus = eventData.bettingStatus;
        this.currentScore = eventData.currentScore;
        this.dailyId = eventData.dailyId;
        this.ended = Boolean(eventData.ended);
        this.eventType = eventData.eventType;
        this.hasLiveCoverage = Boolean(eventData.hasLiveCoverage);
        this.hasTvCoverage = Boolean(eventData.hasTvCoverage);
        this.id = eventData.id;
        this.isLocked = Boolean(eventData.isLocked);
        this.isTopEvent = Boolean(eventData.isTopEvent);
        this.isUpcoming = Boolean(eventData.isUpcoming);
        this.liveChange = eventData.liveChange;
        this.liveStatus = eventData.liveStatus;
        this.longTermId = eventData.longTermId;
        this.matchStatus = eventData.matchStatus;
        this.matchTime = eventData.matchTime;
        this.name = eventData.name;
        this.note = eventData.note;
        this.result = eventData.result;
        this.season = eventData.season;
        this.source = new Source(eventData.source);
        this.sportCategoryId = eventData.sportCategoryId;
        this.sportId = eventData.sportId;
        this.startTime = eventData.startTime;
        this.stopReason = eventData.stopReason;
        this.teamOneId = eventData.teamOneId;
        this.teamTwoId = eventData.teamTwoId;
        this.totalOfferCount = eventData.totalOfferCount;
        this.tournamentId = eventData.tournamentId;
        this.weeklyId = eventData.weeklyId;

        this.liveStreamStatus = eventData.liveStreamStatus;
    }
}
