import { ArgumentNullError } from "../../errors";

import { INameExpression, INameExpressionFactory, IOperandFactory, ISpecifiers } from "./types";
import { OperandFactory } from "./OperandFactory";
import { CardinalNameExpression, MinusNameExpression, OrdinalNameExpression, PlusNameExpression } from "./expressions";

import NameExpressionHelpers from "./NameExpressionHelpers";
import Utility from "./utility";

export default class NameExpressionFactory implements INameExpressionFactory {
    operandFactory: IOperandFactory;

    constructor() {
        this.operandFactory = new OperandFactory();
    }

    buildExpression(specifiers: ISpecifiers, operator: string, operand: string): INameExpression {
        // TODO: pass event details maybe?

        if (operand == null || operand === '') {
            throw new ArgumentNullError('operand');
        }

        if (operator == null || operator === '') {
            Utility.ensureSpecifiersNotNull(specifiers);

            return new CardinalNameExpression(this.operandFactory.buildOperand(specifiers, operand));
        }

        switch (NameExpressionHelpers.operators.indexOf(operator)) {
            case 0: // +
                return new PlusNameExpression(this.operandFactory.buildOperand(specifiers, operand));
            case 1: // -
                return new MinusNameExpression(this.operandFactory.buildOperand(specifiers, operand));
            case 2: // $
                return new CardinalNameExpression(this.operandFactory.buildOperand(specifiers, operand));
            case 3: // %
                return new CardinalNameExpression(this.operandFactory.buildOperand(specifiers, operand));
            case 4: // !
                return new OrdinalNameExpression(this.operandFactory.buildOperand(specifiers, operand));
            default:
                throw new Error(`Operator ${operator} is not supported. Supported operators are ${NameExpressionHelpers.operators.join(',')}.`);
        }
    }
}