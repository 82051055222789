import { IStorageProvider } from './IStorageProvider';

export class LocalStorageProvider implements IStorageProvider {
	init<T>(key: string, initialValue: T): Promise<T> {
		const item = localStorage.getItem(key);

		if (item == null) {
			return this.set(key, initialValue);
		}

		return Promise.resolve(JSON.parse(item));
	}

	get<T>(key: string): Promise<T | undefined> {
		const item = localStorage.getItem(key);

		if (item != null) {
			return Promise.resolve(JSON.parse(item));
		}

		return Promise.resolve(undefined);
	}

	set<T>(key: string, value: T): Promise<T> {
		localStorage.setItem(key, JSON.stringify(value));

		return Promise.resolve(value);
	}

	remove(key: string): Promise<void> {
		localStorage.removeItem(key);

		return Promise.resolve();
	}

	flush(): Promise<void> {
		localStorage.clear();

		return Promise.resolve();
	}

}