import { BetSlipOfferSportData } from '../BetSlipOfferSportData'
import {BetSlipOffer}from'../BetSlipOffer'

export class BetSlipUserValidationOnBetSlipSubmitError {
    errorMessage: string;
    statusCode: number;
    errorCode?: number;

    /** @todo is this from response? */
    totalNumberOfOffersPerBetSlip?: string;
    /** @todo is this from response for code 400007 Added during client side mapping. */
    details: string;
    /** @todo is this from response? */
    suggestedValue?: string;

    /** Added during client side mapping. */
    sportData?: BetSlipOffer[]
    /** Added during client side mapping. */
    event?: BetSlipOfferSportData
    /** Added during client side mapping. */
    eventTwoId?: string;
    /** Added during client side mapping. */
    bettingOfferId?: string;
    /** Added during client side mapping. */
    bettingOfferKeyId?: string;
    /** Added during client side mapping. */
    eventId?: string;
    /** Added during client side mapping. */
    bettingTypeOne?: string;
    /** Added during client side mapping. */
    bettingTypeTwo?: string;
    /** Added during client side mapping. */
    correlationId?: string;
    /** Added during client side mapping. */
    correlationTwoId?: string;
}