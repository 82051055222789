import { createContext } from "react";

import { Sport as SportModel } from '@gp/models';
import { IMainSportHeaders } from '@gp/offer';

export class SportContext extends SportModel {
	headers?: IMainSportHeaders;
	isLive: boolean = false;
	isOutright: boolean = false;
}

export const SportOfferContext = createContext<SportContext>({
	id: '',
	abrv: '',
	name: '',
	sortOrder: 0,
	headers: undefined,
	isLive: false,
	isOutright: false
});