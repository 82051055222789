import { BetSlipCombination } from "./BetSlipCombination";
import { BetSlipType } from "./BetSlipType";
import { BetSlipOffer}from './BetSlipOffer'

export class BetSlip {
    id: string;
    // eventId: string;
    bankCount: number;
    betOfferCount: number;
    betSlipCombinations?: BetSlipCombination;
    betSlipNumber: any;
    betSlipOffers: BetSlipOffer[];
    betSlipType: BetSlipType;
    betStatus: any;
    betSlipStatus: any;
    bonusPercentage: number;
    calculatedCashoutAmount?: number;
    cashoutGain?: number;
    combinationBettingOffersBankCount: number;
    combinationBettingOffersCount: number;
    eventCount: number;
    feePercentage: number;
    gain: number;
    gainBonus?: number;
    gainBonusPercentage?: number;
    gainTax?: number;
    gainTaxPercentage?: number;
    handlingFee: number;
    handlingFeePercentage: number;
    isCancellationAvailable: boolean;
    isLive: boolean;
    maxCoefficient: number;
    maxGain: number;
    notValid: boolean;
    payment: number;
    paymentPerCombination: number;
    stake: number;
    stakePerCombination: number;
    system?: string;
    taxFreePayoutAmount?: number;
    totalNumberOfCombinations: number;

    /** @todo check this one */
    minGain: number;
    bankOfferCount: number;
}