import { ComparerFn, ComparerResult } from "../common";

export interface IIdType {
	id: string;
}

/**
 * Compares elements by id property
 */
export const idComparer: ComparerFn<IIdType> = function (a: IIdType, b: IIdType): ComparerResult {
	if (a.id < b.id) return -1;
	if (a.id > b.id) return 1;

	return 0;
}