import { EventKey } from "@gp/models";
import { constants, getSortedEventKeysBySpecifierValue, Selector, SportSelectors } from "@gp/offer";

import { ColumnType } from "./ColumnType";

import { OfferColumnConfigurationResult } from './getOfferColumns';


export function getOfferAllColumns(eventKeys: EventKey[], selectors: SportSelectors, numberOfColumns: number = 4): OfferColumnConfigurationResult {
	const initialValue = new OfferColumnConfigurationResult();

	if (selectors == null) {
		return initialValue;
	}

	Object.keys(selectors)
		.filter(col => col <= `${numberOfColumns}`)
		.forEach(col => {
			const selector: Selector = selectors[col];

			if (selector == null) {
				initialValue.columns.push({ id: col, type: ColumnType.EMPTY });
				return;
			}

			// if (selector.bettingType === constants.resultPlaceholder) {
			if (selector.bettingTypes.includes(constants.resultPlaceholder)) {
				initialValue.columns.push({ id: col, type: ColumnType.RESULT });
				return;
			}

			// find first key matched by betting type
			let columnKeys: EventKey[] = eventKeys.filter(ek => selector.bettingTypes.includes(ek.bettingType.abrv));

			// sort event keys by specifier
			columnKeys = getSortedEventKeysBySpecifierValue(columnKeys);

			if (columnKeys == null || columnKeys.length == 0) {
				initialValue.columns.push({ id: col, type: ColumnType.EMPTY });
				return;
			}

			columnKeys.forEach(columnKey => {
				initialValue.columns.push({
					id: columnKey.id,
					type: ColumnType.TIP,
					componentProps: {
						offerKeyId: columnKey.id,
						bettingType: columnKey.bettingType,
						tips: selector.tips
					}
				});
			});

			initialValue.nonEmptyColumns += 1;
			initialValue.isEmpty = false;
		});

	return initialValue;
}
