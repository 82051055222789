import { Event } from '@gp/models';
import { ComparerFn, ComparerResult } from '../common';

/**
 * Compares events by startTime property
 */
export const startTimeComparer: ComparerFn<Event> = function(a: Event, b: Event): ComparerResult {
	if (a.startTime < b.startTime) return -1;
	if (a.startTime > b.startTime) return 1;

	return 0;
}