import { IStorageProvider } from './IStorageProvider';

export class SessionStorageProvider implements IStorageProvider {
	init<T>(key: string, initialValue: T): Promise<T> {
		const item = sessionStorage.getItem(key);

		if (item == null) {
			return this.set(key, initialValue);
		}

		return Promise.resolve(JSON.parse(item));
	}

	get<T>(key: string): Promise<T | undefined> {
		const item = sessionStorage.getItem(key);

		if (item != null) {
			return Promise.resolve(JSON.parse(item));
		}

		return Promise.resolve(undefined);
	}

	set<T>(key: string, value: T): Promise<T> {
		sessionStorage.setItem(key, JSON.stringify(value));

		return Promise.resolve(value);
	}

	remove(key: string): Promise<void> {
		sessionStorage.removeItem(key);

		return Promise.resolve();
	}

	flush(): Promise<void> {
		sessionStorage.clear();

		return Promise.resolve();
	}

}