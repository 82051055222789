import { EventOffer } from "./EventOffer";

export class TournamentOffer {
	/**
	 * Null if isSpecial is true
	 * @see isSpecial
	 */
	tournamentId?: string;
	isSpecial: boolean;
	events: EventOffer[];
}