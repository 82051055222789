import { BetSlip } from "./BetSlip";
import { BetSlipAvailableCombination } from "./BetSlipAvailableCombination";
import { BetSlipDelay } from "./BetSlipDelay";

import { BetSlipProcessingError, BetSlipUserValidationOnBetSlipSubmitError, BetSlipValidationError } from "./errors";

export class BetSlipResponse {
    availableCombinations?: BetSlipAvailableCombination[];
    betSlip: BetSlip | null;
    betSlipProcessingResponses: BetSlipProcessingError[];
    submitLiveBetSlipDelayTimeRespones: BetSlipDelay[];
    userValidationOnBetSlipSubmitResponses?: BetSlipUserValidationOnBetSlipSubmitError[];
    validationErrors: BetSlipValidationError[];
    /** @todo check this type */
    isRequestForPaymentEnabled?: unknown;
}