import { ComparerFn } from "../common";

/**
 * Inserts element in a sorted array at the position given by comparer
 * @param array source array
 * @param element element to insert
 * @param comparer comparer function
 */
export function insert<T>(array: T[], element: T, comparer: ComparerFn<T>): T[] {
    array.splice(locationOf(array, element, comparer) + 1, 0, element);
    return array;
}

/**
 * Finds the location in an array where to insert an element
 * @param array source array
 * @param element element for insertion
 * @param comparer comparer function
 * @param start starting point for search for correct place of insertion
 * @param end ending point for search for correct place of insertion
 * @returns place in an array where to insert an element
 */
export function locationOf<T>(array: T[], element: T, comparer: ComparerFn<T>, start?: number, end?: number): number {
    if (array.length === 0) {
        return -1;
    }

    start = start || 0;
    end = end || array.length;

    let pivot = (start + end) >> 1;

    const comparerResult = comparer(element, array[pivot]);
    if ((end - start) <= 1) {
        return comparerResult === -1 ? pivot - 1 : pivot;
    }

    switch (comparerResult) {
        case -1: return locationOf(array, element, comparer, start, pivot);
        case 0: return pivot;
        case 1: return locationOf(array, element, comparer, pivot, end);
    }
}

/**
 * Gets intersecting elements of provided arrays
 * @param array1 
 * @param array2 
 * @param compareFn 
 */
export function getIntersection<T>(array1: T[], array2: T[], compareFn?: (a: T, b: T) => boolean): T[] {
    if (compareFn != null) {
        return array1.filter(a1 => array2.findIndex(a2 => compareFn(a1, a2)) !== -1);
    }

    return array1.filter(a1 => array2.indexOf(a1) !== -1);
}

/**
 * Checks if two arrays have intersecting elements.
 * @param array1 
 * @param array2 
 * @param property 
 * @returns true if arrays have intersecting elements, false otherwise
 */
export function checkIntersect<T>(array1: T[], array2: T[], property: string = null): boolean {
    if (property != null && property !== '') {
        return getIntersection<T>(array1, array2, (a, b) => a[property] === b[property]).length > 0;
    }

    return getIntersection<T>(array1, array2).length > 0;
}

/**
 * Returns unique array elements
 * @param array 
 */
export function getUniqueElements<T>(array: T[]): T[] {
    return array.filter((value, idx, arr) => arr.indexOf(value) === idx);
}

export type ObjectKey = string | number | symbol;
export function groupBy<K extends ObjectKey, T extends Record<K, ObjectKey>>(array: T[], key: K): Record<ObjectKey, T[]> {
    return array.reduce((acc, item) => {
        const groupId = item[key];
        acc[groupId] = acc[groupId] || [];
        acc[groupId].push(item);

        return acc;
    }, {} as Record<ObjectKey, T[]>);
}