import { BetSlipOfferSportData } from "./BetSlipOfferSportData";
import { Result } from '../../offer/Result'
import { MatchTime } from '../../offer/MatchTime'
import { BetSlipValidationError} from './errors/BetSlipValidationError'

export class BetSlipOffer {
    bettingOfferId: string;
    bettingOfferKeyId: string;
    bettingOfferStatus: number;
    bettingTypeId: string;
    bettingTypeTip: string;
    error?: any;
    eventId: string;
    id: string;
    isBank: boolean;
    specifier?: any;
    sportData: BetSlipOfferSportData;
    currentResult: string;
    systemIdentifier: string;
    value: number;

    /** @todo is this from response? */
    playerId?: string;
    /** @todo is this from response? */
    playerFirstName?: string;
    /** @todo is this from response? */
    playerLastName?: string
    /** @todo is this from response? */
    teamId?: string;
    /** @todo is this from response? */
    teamName?: string;
    /** @todo is this from response? */
    displayTip?: string;

    /** Added during client side mapping. */
    indicator?: number;
    /** Added during client side mapping. */
    isEventDeleted?: boolean;
    /** Added during client side mapping. */
    isKeyLocked?: boolean;
    /** Added during client side mapping. */
    isOfferLocked?: boolean;
    /** Added during client side mapping. */
    isEventLocked?: boolean;
    /** Added during client side mapping. */
    isExcluded?: boolean;
    /** Added during client side mapping. */
    eventResultData?: Result;
    /** Added during client side mapping. */
    matchTime?: MatchTime;
    /** Added during client side mapping. */
    isLocked?: boolean;
    /** Added during client side mapping. */
    isDeleted?: boolean;
    /** Added during client side mapping. */
    errors?: BetSlipValidationError[]
}