import { HomeAwayScore } from "./HomeAwayScore";

export class ScorePerPeriod extends HomeAwayScore {
    /**
     * Period name
     */
    name: string;
    type: PeriodType;
    periodNumber: number;
}

export enum PeriodType {
    HALF = "HALF",
    OT = "OT",
    PEN = "PEN",
}