import { IExtensions } from "../types/IExtensions";

const extensions: { [index: string]: string[] } = {
    // en: [
    //     'th', // default
    //     'st', // first
    //     'nd', // second
    //     'rd', // third
    // ],
    default: ['.', '.', '.', '.']
}

class OrdinalExtensions implements IExtensions {
    getExtensions(locale: string = 'en'): string[] {
        if (extensions[locale] == null) {
            return extensions['default'];
        }

        return extensions[locale];
    }
}

const inst = new OrdinalExtensions();
export default inst;