import { FormatError } from "../../errors";
import { ExpressionOperand } from "./ExpressionOperand";
import { SimpleOperand } from "./SimpleOperand";
import { IOperand, IOperandFactory, ISpecifiers, MathOperations } from "./types";

export class OperandFactory implements IOperandFactory {
    buildOperand(specifiers: ISpecifiers, operandExpression: string): IOperand {
        if ((operandExpression.startsWith('(') && !operandExpression.endsWith(')')) ||
            (!operandExpression.startsWith('(') && operandExpression.endsWith(')'))) {
            throw new FormatError('Invalid operand format. It contains open paranthesis');
        }

        // check if we have expression operand
        if (operandExpression.startsWith('(') && operandExpression.endsWith(')')) {
            // remove paranthesis
            operandExpression = operandExpression.substr(1, operandExpression.length - 2);

            let mathOperation: MathOperations;
            let parts: string[];
            if (operandExpression.includes('+')) {
                mathOperation = MathOperations.ADD;
                parts = operandExpression.split('+');
            }
            else if (operandExpression.includes('-')) {
                mathOperation = MathOperations.SUBTRACT;
                parts = operandExpression.split('-');
            }
            else {
                throw new FormatError('Operand does not contain an operation identifier.');
            }

            if (parts.length !== 2) {
                throw new FormatError('Operand contains more than one operation identifier.');
            }

            const mathOperationValue = Number(parts[1]);
            return new ExpressionOperand(specifiers, parts[0], mathOperation, mathOperationValue);
        }

        return new SimpleOperand(specifiers, operandExpression);
    }
}