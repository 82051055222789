import { createContext } from 'react';
import { Sport, Category, Tournament, Event, BaseTip, BettingOffer } from '@gp/models';

export type OfferOptions = {
	numberOfColumns: number;
	/**
	 * Duration of highlighter score after change (in ms)
	 * @default 3500
	 */
	scoreChangeDuration: number;
	/**
	 * If true, additional offer will display right below the row it was clicked on. This is default behaviour
	 * @default true
	 */
	enableInlineAdditionalOffer: boolean;
	/**
	 * For live offer render set to true, otherwise is false.
	 * @default false
	 */
	isLive: boolean;

	/**
	 * For live offer favorites rendering. True if favorites are displayed.
	 * @default false
	 */
	isFavorites: boolean;

	onSportClose: (sportId: string) => void;
	onCategoryClose: (categoryId: string, sportId?: string) => void;
	onTournamentClose: (tournamentId: string, categoryId?: string, sportId?: string) => void;

	onTournamentStatisticsOpen: (tournament: Tournament, sport: Sport, category: Category) => void;
	onEventStatisticsOpen: (event: Event) => void;

	/**
	 * If inline additional offer is disabled, this method will be called when additional offer is clicked
	 * @see enableInlineAdditionalOffer
	 */
	onAdditionalOfferClick?: (eventId: string) => void;

	/**
	 * Triggered when tip is clicked
	 */
	onTipClick: (tip: BettingOffer) => void;
	/**
	 * Returns true if tip is selected
	 */
	isTipSelected: (tipId: string) => boolean;

	/**
	 * True if one click bet is active
	 */
	isOneClickBetActive?: boolean;

	/**
	 * Returns true if event is in favorites, false otherwise
	 */
	isEventInFavorites?: (eventId: string) => boolean;

	/**
	 * Called when event favorites icon is clicked and event is not in favorites
	 */
	addEventToFavorites?: (eventId: string) => void;
	/**
	 * Called when event favorites icon is clicked and event is in favorites
	 */
	removeEventFromFavorites?: (eventId: string) => void;
}

const notImplemented = (name: string) => console.error(name, 'is not implemented');

const defaultValue: OfferOptions = {
	numberOfColumns: 4,
	scoreChangeDuration: 3500,
	enableInlineAdditionalOffer: true,
	isLive: false,
	isFavorites: false,

	onSportClose: (sportId: string) => notImplemented('onSportClose'),
	onCategoryClose: (categoryId: string, sportId?: string) => notImplemented('onCategoryClose'),
	onTournamentClose: (tournamentId: string, sportId?: string, categoryId?: string) => notImplemented('onTournamentClose'),

	onTournamentStatisticsOpen: (tournament: Tournament, sport: Sport, category: Category) => notImplemented('onTournamentStatisticsOpen'),
	onEventStatisticsOpen: (event: Event) => notImplemented('onEventStatisticsOpen'),

	onTipClick: (tip: BettingOffer) => notImplemented('onTipClick'),
	isTipSelected: (tipId: string) => false,
}

export const OfferOptionsContext = createContext<Partial<OfferOptions>>(defaultValue);
