import { BaseLookup } from "./BaseLookup";
import { BettingTip } from "./BettingTip";
import { BettingTypeSetting } from "./BettingTypeSetting";

export enum SpecifierType {
    UNKNOWN = -1,
    TIP = 0,
    PLAYER = 1,
    TEAM = 2,
}

type SettingPerSport = { [sportId: string]: BettingTypeSetting };

export class BettingType<TTip = BettingTip> extends BaseLookup {
    /**
     * Name for bet slip
     */
    nameForBettingSlip: string;
    /**
     * Name for offer list
     */
    nameForOfferList: string;
    description: string;
    /**
     * Indicates type of betting type tips.
     * You can use this property to alter rendering.
     */
    specifierType: SpecifierType;
    /**
     * Betting type settings per each sport.
     * Sort order for certain betting types can vary depending on sports they are tied to
     */
    settingsPerSport?: SettingPerSport;
    /**
     * List of tip ids
     */
    tips?: TTip[];
    /**
     * Betting type group it belongs to
     */
    groupId?: string;

    /**
     * Simple betting type name (without specifiers)
     */
    simpleName: string;
}