import { Event } from '@gp/models';
import { ComparerFn, ComparerResult } from '../common';

/**
 * Compares events by isTopEvent property
 */
export const isTopEventComparer: ComparerFn<Event> = function(a: Event, b: Event): ComparerResult {
	if(!!a.isTopEvent === !!b.isTopEvent) return 0;

	return !!a.isTopEvent ? -1 : 1;

	// if (!!a.isTopEvent > !!b.isTopEvent) return -1;
	// if (!!a.isTopEvent < !!b.isTopEvent) return 1;

	// return 0;
}