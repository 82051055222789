import { Event } from "../Event";
import { KeyOffer } from "./KeyOffer";

export class EventOffer {
	eventId: string;
	event?: Event;
	offers?: KeyOffer[];

	constructor(eventOfferData: any) {
		const eventId = eventOfferData['eventId'];
		const event = eventOfferData['event'];
		const offers = eventOfferData['offers'];

		if (eventId) {
			this.eventId = eventId;
		}
		if (event) {
			this.event = new Event(event);
		}
		if (offers) {
			this.offers = offers;
		}
	}
}
