import { ComparerFn, ComparerResult } from "../common";

export interface IIsLiveType {
	isLive: boolean;
}

export const isLiveComparer: ComparerFn<IIsLiveType> = function (a: IIsLiveType, b: IIsLiveType): ComparerResult {
	if (!!a.isLive === !!b.isLive) return 0;

	return !!a.isLive ? -1 : 1;
}

export const reverseIsLiveComparer: ComparerFn<IIsLiveType> = function (a: IIsLiveType, b: IIsLiveType): ComparerResult {
	if (!!a.isLive === !!b.isLive) return 0;

	return !!a.isLive ? 1 : -1;
}