/**
 * Formats odd for displaying. Odds between 1 and 100 are displayed with 2 decimal places, above 100 without decimal places.
 * If odd is less than or equal to 1, then null is returned.
 * @param value odd value
 */
export function toDisplayOdd(value: number | string): string {
    if (typeof value === 'string') {
        value = Number(value);
    }

    if (isNaN(value) || value <= 1) {
        return null;
    }

    if (value > 1 && value < 100) {
        return value.toFixed(2);
    }

    return value.toFixed(0);
}