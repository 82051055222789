import { BettingOffer } from "../BettingOffer";

type IndicatorValue = -1 | 0 | 1;

export class EventKeyBettingOffer extends BettingOffer {
    keyId: string;
    eventId: string;
    isLive?: boolean;
    indicator?: IndicatorValue;
    teamAbrv?: string;

    constructor(eventKeyData: any) {
        super(eventKeyData);

        this.keyId = eventKeyData.keyId;
        this.eventId = eventKeyData.eventId;
        this.isLive = eventKeyData.isLive;
        this.indicator = eventKeyData.indicator;
        this.teamAbrv = eventKeyData.teamAbrv;
    }
}