/**
 * Returns true if input string is null or empty, false otherwise
 * @param input 
 */
export function isStringNullOrEmpty(input: string): boolean {
    return input == null || typeof(input) !== 'string' || input.trim() === '';
}

/**
 * Returns object key/values in query string format
 * @param input any object
 * @returns query string
 */
export function toQueryParamString(input: Object): string {
    const reducer = (obj, parentPrefix = null) => (prev, key) => {
        const val = obj[key];
        key = encodeURIComponent(key);
        const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

        if (val == null || typeof val === 'function') {
            prev.push(`${prefix}=`);
            return prev;
        }

        if (['number', 'boolean', 'string'].includes(typeof val)) {
            prev.push(`${prefix}=${encodeURIComponent(val)}`);
            return prev;
        }

        prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
        return prev;
    };

    return Object.keys(input).reduce(reducer(input), []).join('&');
}